  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(to right, #38c29d, #549fe0);
    /* background:  #fff; */
    color: #ffebee; 
    position: sticky;
    width: 100% ;
    top: 0px; 
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
    z-index: 1000;
  }
     
  .nav ul li a {
    /* color: #ffffff; */
    color: #000000; 
    padding: 4px 15px;
    transition: color 0.3s ease;
}
  
  .nav ul li a:hover {
    color: #ffcccb; 
  }
  
.nav_header_lst ul {
    display: flex;
}
.nav_header_lst li {
    padding: 20px 20px;
}
ul.nav_ul {
    list-style-type: none;
    display: flex;
    align-items: center;
}
a {
    text-decoration: none;
}
ul.nav_ul li a {
    text-decoration: none;
    display: flex;
    font-size: 19px;
    font-family: "Roboto", sans-serif;
    font-weight: 600;
}
li.nav_call a {
  font-family: "Roboto", sans-serif ;
  font-weight: 400 ;
  font-size: 22px ;
}
ul.nav_ul li a:hover {
    text-decoration: none;
    color: #000;
}
.logo img {
    width: 153px;
}
.logo {
    width: 20%;
    text-align: end;
}
nav.nav {
    width: 80%;
    display: flex;
    justify-content: center;
}
.mobile-menu-toggle {
    display: none;
    font-size: 1.8rem;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    background: none;
    color: black;
    border: none;
    cursor: pointer;
  }
 
  @media (max-width: 768px) {
    .nav ul {
      flex-direction: column;
      position: absolute;
      top: 60px;
      right: 0;
      background-color: #333;
      width: 200px;
      height: 0;
      overflow: hidden;
      transition: height 0.3s ease-in-out;
    }
  
    .nav.open ul {
      height: auto;
    }
  
    .nav ul li {
      margin: 10px 0;
      text-align: center;
    }
  
    .mobile-menu-toggle {
      display: block;
    }
  
    .nav ul {
      display: none;
    }
  
    .header {
        width: 100%;
        margin: 0px;
    }
    .nav.open ul {
        display: block;
        height: auto;
        width: 100% ;
        padding: 0px;
        margin: 20px 0px 0px 0px;
        background-color: #000;
    }
    .sticky-header {
        justify-content: space-between;
    }
    .logo img {
        width: 120px;
    }
    .logo {
        width: 20%;
        text-align: right;
    }
    ul.nav_ul li a {
          color: #fff;
  }
  }
  