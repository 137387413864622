.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    overflow: auto; 
  }
  
  .modal-content {
    background-color: #fff;
    border-radius: 8px;
    width: 90%;
    max-width: 500px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    overflow: hidden; 
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    background-color: #2d333b;
    color: white;
  }
  
  .modal-title {
    font-size: 1.5em;
    margin: 0;
  }
  
  .modal-body {
    padding: 20px;
  }
  
  .modal_form__group {
    display: flex;
    flex-direction: column-reverse; 
    margin-bottom: 10px;
  }
  
  .modal_form__field {
    width: 90%;
    padding: 9px; 
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1em;
    transition: border 0.3s;
  }
  
  .modal_form__field:focus {
    border-color: #4a90e2;
    outline: none;
  }
  
  .btn {
    background-color: #4a90e2;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    font-size: 1em;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 100%; 
  }
  
  .btn:hover {
    background-color: #357ab8;
  }
  
  @media (max-width: 600px) {
    .modal-content {
      width: 95%;
      padding: 10px; 
    }
  
    .modal-header {
      padding: 10px; 
    }
  
    .modal-title {
      font-size: 1.3em; 
    }
  
    .modal_form__field {
      padding: 10px; 
    }
  
    .btn {
      padding: 10px;
    }
  }
  
  .modal-body {
    overflow-y: auto; 
    max-height: 400px; 
    background: linear-gradient(to right, #9ed9c9, #88accb);
  }
  