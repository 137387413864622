/* Hero Section */
  .hero {
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
    background-size: cover;
    background-position: center;
  }
  
  .hero-title {
    font-size: 2.5rem;
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    padding: 0 15px;
    color: #000;
  }
  
  /* About Section */
  .about {
    padding: 50px 20px;
    background-color: #f9f9f9;
    text-align: center;
  }
  
  .about h2 {
    font-size: 2rem;
    font-family: "Roboto", sans-serif;
    margin-bottom: 20px;
    color: #333;
  }
  
  .about p {
    font-size: 1.2rem;
    font-family: "Roboto", sans-serif;
    margin-bottom: 40px;
    color: #555;
  }
  
  /* Grid Layout for About Items */
  .about-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    margin-top: 20px;
  }
  
  .about-item {
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0.1, 0.2, 0.1, 0.2);
    transition: transform 0.3s ease-in-out;
  }
  
  .about-item:hover {
    transform: scale(1.05);
  }
  
  .about-image {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
    margin-bottom: 15px;
  }
  
  .about-item h3 {
    font-size: 1.7rem;
    font-family: "Roboto", sans-serif;
    color: #333;
    margin-bottom: 10px;
  }
  
  .about-item p {
    font-size: 1.1rem;
    font-family: "Roboto", sans-serif;
    color: #555;
  }
  
  /* Responsive Design */
  @media screen and (max-width: 768px) {
    .hero-title {
      font-size: 2rem;
    }
  
    .about h2 {
      font-size: 1.8rem;
    }
  
    .about p {
      font-size: 1rem;
    }
  
    .about-grid {
      grid-template-columns: 1fr; /* Single column layout for tablets and below */
    }
  
    .about-item {
      padding: 15px;
    }
  
    .about-item h3 {
      font-size: 1.2rem;
    }
  
    .about-item p {
      font-size: 0.9rem;
    }
  }
  
  @media screen and (max-width: 480px) {
    .hero {
      height: 40vh; /* Smaller height for mobile */
    }
  
    .hero-title {
      font-size: 1.8rem;
    }
  
    .about h2 {
      font-size: 1.6rem;
    }
  
    .about p {
      font-size: 0.9rem;
    }
  
    .about-item {
      padding: 10px;
    }
  
    .about-item h3 {
      font-size: 1rem;
    }
  
    .about-item p {
      font-size: 0.8rem;
    }
  }
  