.footer {
    background-color: #d9cfc2; 
    color: #000;
    padding: 40px 0 0 0;
    text-align: center;
  }
  
  span.fotter_span {
    font-size: 18px;
    font-family: "Roboto", sans-serif;
    font-weight: 500 ;
}

  .footer-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: start;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .footer-logo-info {
    flex: 1 1 200px;
    margin-bottom: 20px;
  }
  
  .footer-logo {
    width: 180px;
    margin-bottom: 15px;
    /* background-color: #000; */
  }
  
  .footer-description {
    font-size: 16px;
    line-height: 1.5;
    color: #000;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
}

span.footer_no_span {
  font-weight: 500 ;
  font-family: "Roboto", sans-serif;
}
  
  .footer-navigation {
    flex: 1 1 200px;
    margin-bottom: 20px;
  }
  
  .footer-navigation a {
    display: block;
    color: #000; 
    text-decoration: none;
    margin: 5px 0;
    font-size: 19px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    transition: color 0.3s ease;
  }
  
  .footer-navigation a:hover {
    color: #871e0c; 
  }
  
  .footer-contact {
    flex: 1 1 250px;
    margin-bottom: 20px;
  }
  .footer-navigation h3 {
    color: #871e0c; 
    margin-bottom: 10px;
    font-size: 24px;
    /* font-family: "Flamenco", system-ui; */
    font-family: "Roboto", sans-serif;
    font-weight: 500;
  }
  
  .footer-contact h3 {
    color: #871e0c; 
    margin-bottom: 10px;
    font-size: 24px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
  }
  
  .footer-contact p {
    margin: 5px 0;
    font-size: 19px;
    color: #000;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
  }
  
  .footer-social {
    flex: 1 1 150px;
    margin-bottom: 20px;
  }
  
  .footer-social h3 {
    color: #871e0c; 
    margin-bottom: 10px;
    font-size: 24px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
  }
  
  .footer-social a {
    color: #000;
    margin: 0 10px;
    font-size: 24px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    transition: color 0.3s ease;
  }

  .footer_web_counter a{
    color: #000;
    margin: 0 10px;
    font-size: 24px;
    font-family: auto;
    font-weight: 500;
  }
  
  .footer-social a:hover {
    color: #871e0c;
  }
  
  .footer-bottom {
    padding: 20px 0;
    font-size: 18px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    border-top: 1px solid #000;
  }
  
  .footer-bottom p {
    margin: 0;
    color: #000;
  }
  
  @media (max-width: 768px) {
    .footer-container {
      flex-direction: column;
      align-items: center;
    }
  
    .footer-navigation, .footer-contact, .footer-logo-info, .footer-social {
      text-align: center;
      flex: 1 1 100%;
    }
  }
  