
.concrete-blocks-packages {
    padding: 40px; 
}

.concrete-blocks-packages h1 {
    text-align: center;
    color: #333;
    margin-bottom: 10px;
    font-size: 2.3rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
}

.concrete-blocks-packages-container {
    display: flex;
    justify-content: space-between; 
    flex-wrap: wrap; 
    gap: 15px; 
}

.concrete-blocks-package {
    /* background: linear-gradient(to right, #9ed9c9, #88accb); */
    border: 1px solid #ced4da; 
    border-radius: 10px; 
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); 
    overflow: hidden; 
    transition: transform 0.3s, box-shadow 0.3s;
    padding: 10px; 
    flex: 1 1 calc(30% - 10px); 
    max-width: calc(30% - 10px); 
    background-color: #f8f9fa;
}

.concrete-blocks-package:hover {
    transform: translateY(-5px); 
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2); 
}

.concrete-blocks-package-header {
    background: #313a43; 
    color: #fff; 
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    transition: background 0.3s; 
    font-size: 1.2rem; 
    /* border-bottom: 2px solid #0056b3;  */
    border-radius: 8px; 
}

.concrete-blocks-package-header h2 {
    font-size: 24px;
    margin: 0px;
    font-family: "Roboto", sans-serif;
}

.concrete-blocks-package-header p {
    font-size: 20px;
    margin: 0px;
    font-family: "Roboto", sans-serif;
}

.concrete-blocks-package-header:hover {
    background: #0056b3; 
}

.concrete-blocks-package-details {
    padding: 15px;
    border-radius: 8px; 
}

.concrete-blocks-feature-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    /* background: #e7f1ff;  */
    border-radius: 5px;
    margin: 8px;
    cursor: pointer;
    transition: background 0.3s; 
    font-size: 1rem;
    color: #000; 
    border: 1px solid #c5bdbd;
}

.concrete-blocks-feature-details ul li span {
    font-size: 16px;
    font-weight: 600;
}

.concrete-blocks-feature-header h3 {
    font-size: 17px;
    margin: 2px;
    font-family: "Roboto", sans-serif;
}

.concrete-blocks-feature-header:hover {
    background: #d0e2ff; 
}

.concrete-blocks-feature-details {
    padding: 10px 0; 
    animation: fadeIn 0.3s; 
}

.concrete-blocks-feature-details ul {
    list-style-type: none; 
    padding-left: 0; 
}

.concrete-blocks-feature-details ul li {
    padding: 5px 0; 
    border-bottom: 1px solid #e7e7e7; 
}

@media (max-width: 899px) {
    .concrete-blocks-package {
        flex: 1 1 calc(30% - 10px); 
        max-width: calc(30% - 10px); 
    }
}

@media (max-width: 599px) {
    .concrete-blocks-package {
        flex: 1 1 100%; 
        max-width: 100%; 
        margin-bottom: 10px; 
    }

    .concrete-blocks-packages h1 {
        font-size: 1.2rem; 
    }
    .concrete-blocks-packages {
        padding: 20px;
    }
    .concrete-blocks-package-header {
        font-size: 1rem; 
    }
    .concrete-blocks-package-header h2 {
        font-size: 17px;
    }
    .concrete-blocks-package-header p {
        font-size: 15px;
    }
    .concrete-blocks-feature-header h3 {
        font-size: 16px;
    }
    .concrete-blocks-feature-header {
        font-size: 1rem; 
        margin: 5px;
    }
    .concrete-blocks-feature-details ul li span {
        font-size: 14px;
    }
    .concrete-blocks-feature-details ul li {
        font-size: 14px;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
