.home_mn_carousel {
    position: static;
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
  }
  
  .home_mn_carousel_image {
    width: 100%;
    height: auto;
    display: block;
  }

  div#root {
    background-color: #cfedda;
}

.home_con_se_container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.hom_con_se_section_title h3 {
  font-size: 34px;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  margin: 10px 0px;
}

.hom_con_se_section_title p {
  font-size: 20px;
  color: #615a5a;
  text-align: center;
  margin-bottom: 40px;
  font-family: "Roboto", sans-serif;
}

.home_con_se_row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.col-md-4 {
  flex: 1 1 30%;
  padding: 15px;
  box-sizing: border-box;
}

.col-sm-6 {
  flex: 1 1 45%;
  padding: 15px;
}

.services {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
}

.services img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

.hom_con_se_post-header h4 {
  font-size: 20px;
  margin-top: 15px;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
}

.hom_con_se_post-header .date {
  font-size: 17px;
  color: #000000;
  font-family: "Roboto", sans-serif;
  font-weight: normal;
}

.services p {
  font-size: 20px;
  color: #000;
  font-family: "Roboto", sans-serif;
  line-height: 1.6;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-weight: 400;
}

hr {
  width: 50px;
  margin: 10px auto;
  border: 2px solid #ccc;
}

@media (min-width: 992px) {
  .col-md-4 {
    max-width: 30%;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .col-md-4, .col-sm-6 {
    flex: 1 1 45%;
    max-width: 45%;
  }
}

@media (max-width: 767px) {
  .home_con_se_row {
    flex-direction: column;
  }
  
  .col-md-4, .col-sm-6 {
    flex: 1 1 100%;
    max-width: 100%;
    padding: 10px;
  }

  .hom_con_se_section_title h3 {
    font-size: 26px;
  }

  .services {
    padding: 15px;
  }
}

.section-title {
  font-size: 2em;
  margin-bottom: 10px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
}

.section-subtitle {
  font-size: 20px;
  /* font-family: "Flamenco", system-ui; */
  font-family: "Roboto", sans-serif;
  margin-bottom: 20px;
}

.bb-why-us__row {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
}

.bb-why-us__grid {
  background: #f9f9f9;
  padding: 15px;
  border-radius: 8px;
  text-align: center;
  flex: 1;
  min-width: calc(25% - 20px); 
  box-sizing: border-box;
}

.bb-why-us__grid__image img {
  max-width: 100%;
  height: auto;
}

.bb-why-us__grid__title {
  font-size: 20px;
  margin: 10px 0;
  font-family: "Roboto", sans-serif;
}

.bb-why-us__grid__text {
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  color: #000;
}

@media (max-width: 767px) {
  .section-title {
      font-size: 1.5em;
  }

  .section-subtitle {
      font-size: 20px;
  }

  .bb-why-us__grid__title {
      font-size: 20px;
  }

  .bb-why-us__grid__text {
      font-size: 17px;
  }

  .bb-why-us__row {
      flex-direction: column;
  }

  .bb-why-us__grid {
      min-width: 100%; 
  }
  .home_mn_carousel_image {
    height: auto;
  }
  .home_mn_carousel_image {
    height: 200px;
  }
}