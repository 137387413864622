/* Gallery Container */
.gallery-container {
    text-align: center;
    padding: 30px;
    background-color: #cfedda;
  }
  
  /* Gallery Title */
  .gallery-container h2 {
    font-size: 3rem;
    font-family: "Roboto", sans-serif;
    color: #000;
    text-align: center;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    /* font-size: 28px;
    color: #333;
    margin-bottom: 20px;
    font-weight: bold; */
  }
  
  /* Image Grid (4 in a row) */
  .gallery {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 15px;
    max-width: 1200px;
    margin: auto;
  }
  
  /* Gallery Items */
  .gallery-item {
    overflow: hidden;
    border-radius: 10px;
    cursor: pointer;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  }
  
  .gallery-item:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }
  
  /* Images */
  .gallery-image {
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-radius: 10px;
    transition: transform 0.3s ease-in-out;
  }
  
  /* Zoom Overlay */
  .zoom-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    animation: fadeIn 0.3s ease-in-out;
  }
  
  /* Zoomed Content */
  /* .zoomed-content {
    position: relative;
    max-width: 90%;
    max-height: 90%;
  }
  
  
  .zoomed-image {
    max-width: 60%;
    max-height: 100%;
    border-radius: 10px;
    transition: transform 0.3s ease-in-out;
  } */
  
  /* Close Button */
  .close-btn {
    position: absolute;
    top: -10px;
    right: 0px;
    background: red;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  }
  
  .close-btn:hover {
    background: darkred;
  }
  
  /* Zoomed Content */
.zoomed-content {
    position: relative;
    max-width: 90%;
    max-height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Zoomed Image */
.zoomed-image {
    max-width: 100%;
    max-height: 90vh; /* Ensures it never overflows the screen */
    border-radius: 10px;
    transition: transform 0.3s ease-in-out;
}

/* Responsive Adjustments */
@media (max-width: 1024px) {
    .zoomed-image {
        max-width: 80%;
        max-height: 85vh;
    }
}

@media (max-width: 768px) {
    .zoomed-image {
        max-width: 90%;
        max-height: 80vh;
    }
}

  /* Responsive Design */
  @media (max-width: 1024px) {
    .gallery {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 768px) {
    .gallery {
      grid-template-columns: repeat(1, 1fr);
    }
    .close-btn {
        right: 0px;
    }
    .zoomed-image {
        max-width: 100%;
    }
  }
  
  /* Fade-in Animation */
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  