/* Hero Section */
.hero {
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
}

.hero-title {
  font-size: 2.5rem;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
}

/* Services Section */
.service-container {
  padding: 20px;
}

.service-header {
  text-align: center;
  margin-bottom: 30px;
}

.service-header h2 {
  font-size: 2rem;
  font-family: "Roboto", sans-serif;
  color: #333;
}

.service-header hr {
  width: 50px;
  margin: 10px auto;
  border: 2px solid #f57c00;
}

/* Services Wrapper */
.services-wrapper {
  display: grid;
  grid-template-columns: repeat(1, 1fr); /* Default 1 column */
  gap: 20px;
}

/* Individual Service Card */
.service {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  transition: transform 0.3s ease;
}

.service:hover {
  transform: scale(1.05);
}

.service img {
  width: 100%;
  max-height: 200px;
  border-radius: 5px;
  object-fit: cover;
  margin-bottom: 15px;
}

.service h5 {
  font-size: 1.5rem;
  font-family: "Roboto", sans-serif;
  color: #f57c00;
  margin: 10px 0;
}

.service p {
  font-size: 1rem;
  font-family: "Roboto", sans-serif;
  color: #555;
  line-height: 1.5;
}

/* Responsive Design */
@media (min-width: 768px) {
  .services-wrapper {
    grid-template-columns: repeat(2, 1fr); /* 2 columns for tablets */
  }
}

@media (min-width: 1024px) {
  .services-wrapper {
    grid-template-columns: repeat(3, 1fr); /* 3 columns for laptops */
  }
}
