/* Main container styling */
.package-main-container {
  font-family: "Roboto", sans-serif;
  margin: 0;
  padding: 0;
}

/* Heading Section */
.packages-header {
  height: 300px; 
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-title {
  font-size: 3rem;
  font-family: "Roboto", sans-serif;
  color: #000;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

/* Subheading styling */
.subheading-container {
  text-align: center;
  margin-top: 50px;
}

.subheading-title {
  font-size: 2rem;
  font-family: "Roboto", sans-serif;
  color: #333;
  font-weight: 600;
}

/* Packages Container */
.packages-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  padding: 20px;
}

.package-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0.1, 0.2, 0, 0.2);
  overflow: hidden;
  text-align: center;
  padding: 20px;
  transition: transform 0.3s ease-in-out;
}

.package-card:hover {
  transform: translateY(-10px);
}

.package-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.package-title {
  font-size: 1.25rem;
  font-family: "Roboto", sans-serif;
  margin-top: 10px;
  color: #333;
}

.package-content {
  font-size: 1rem;
  font-family: "Roboto", sans-serif;
  color: #555;
  margin: 10px 0;
}

.view-more-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

.view-more-button:hover {
  background-color: #0056b3;
}

/* Responsive Design */
@media (max-width: 1200px) {
  .packages-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 900px) {
  .packages-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 600px) {
  .packages-container {
    grid-template-columns: 1fr;
  }

  .header-title {
    font-size: 2.5rem;
  }

  .subheading-title {
    font-size: 1.5rem;
  }
}
