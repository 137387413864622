.contact-container {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  padding: 30px;
  max-width: 1200px;
  margin: auto;
}

.contact-form, .contact-info {
  flex: 1;
  min-width: 280px;
  /* background: linear-gradient(to right, #9ed9c9, #88accb); */
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 20px rgba(0.1, 0.3, 0.3, 0.3);
  transition: transform 0.3s, box-shadow 0.3s;
}

.contact-form:hover, .contact-info:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
}

.contact-form h2, .contact-info h3 {
  margin-bottom: 20px;
  color: #ab0808; 
  text-align: center;
  font-size: 2.5em;
  font-family: "Roboto", sans-serif;
}

.contact-form label {
  display: block;
  margin: 10px 0 5px;
  font-size: 1.4em;
  font-family: "Roboto", sans-serif;
}

.contact-form input,
.contact-form textarea {
  width: 90%;
  padding: 12px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: border-color 0.3s;
}

.contact-form input:focus,
.contact-form textarea:focus {
  border-color: #007bff; 
  outline: none;
}

.contact-form button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 12px 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  font-size: 20px;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
}

.contact-form button:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
}

.form-result {
  margin-top: 10px;
  color: #28a745;
}

.contact-info {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.info-section {
  margin-bottom: 20px;
}

.contact-details {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding: 10px 0;
}

.contact-details svg {
  font-size: 24px;
  margin-right: 10px;
  color: #000;
}

.contact-topic {
  font-weight: bold;
  color: #333;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
}

.contact-text, .contact-text-number {
  font-size: 20px;
  font-family: "Roboto", sans-serif;
}


.contact-map-container {
  margin-top: 20px;
}

.contact-map-container h3 {
  margin: 0;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  text-align: center;
  font-size: 20px;
  font-family: "Roboto", sans-serif;
}

@media (max-width: 768px) {
  .contact-container {
    flex-direction: column;
    padding: 10px;
  }

  .contact-form, .contact-info {
    width: 100%;
    padding: 15px;
  }
  .contact-text {
    font-size: 16px;
    font-family: "Roboto", sans-serif;
}

  .contact-map-container iframe {
    height: 150px;
  }
}

@media (max-width: 480px) {
  .contact-form h2, .contact-info h3 {
    font-size: 1.5em;
  }
  .contact-container {
    width: 85%;
    padding: 15px;
    margin: 0;
  }
  .contact-text {
    font-size: 16px;
    font-family: "Roboto", sans-serif;
}

  .contact-form button {
    padding: 10px;
    font-size: 16px;
  }
}
